import React from "react"
import { Icon, IconButton } from "native-base"
import { Ionicons, MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";
import { IDisposer, onPatch } from "mobx-state-tree";
import { useFocusEffect } from "@react-navigation/native";
import { ITEM_STATUS } from "../../../voolks/types";
import { isElementArchived, openPDF } from "../../../utils/itemCommonFunctions";
import { useDebounce, useDebouncedCallback } from "use-debounce";
import { customApp } from "../../../config";
import { ButtonIcon,Button } from "@gluestack-ui/themed";

function _findField(fields,key) {
  return fields.reduce((obj, f) => {
    if (f.id == key)
      return f;
    if (!obj && f.nodes) return _findField(f.nodes,key)
    return obj
  }, undefined)
}


export const GetActionButtonsFC = ({ itemsStore, navigation, setShouldHide, pageActive, form, saveAndGo, forReview,ItemsListScreenName }) => {
  const [isValidItem, setIsValidItem] = React.useState<boolean>(false);
  console.log("ItemDetailsScreen", "GetActionButtonsFc", "isValidItem", isValidItem, "pageActive", pageActive); 
  const validateItem = () => {
    if (forReview == false && form.pages.length == (pageActive + 1)) {
      console.log("GetActionButtonsFC", "validateItem");
      let isvalid: boolean = true;
      for (const key of itemsStore.itemActive.payload?.keys()) {
        let isArchived: boolean = form.pages.reduce((isArchived, page) => {
          return isElementArchived(page.fields, key, isArchived);
        }, false);
        let element = itemsStore.itemActive.payload.get(key).toJSON();
        if (!(isArchived || element.type=="group" || (element.inactiveGroup == true))) {
          isvalid = (isvalid ? element?.valid == true : isvalid);
          if(!isvalid){
            let field =  _findField(form.pages[0].fields,key);
            console.log("validateItem",key, field.name || field.label,"inactiveGroup",element.inactiveGroup,"isValid", element.valid,"value", element.value)
          }
        }
        //
      }
      isValidItem != isvalid && setIsValidItem(isvalid);
    }

  }
  const validateItemDebounced = useDebouncedCallback(validateItem, 500);
  //const  = customApp.isPTW ? "PTWHome" : "ItemsList";
  useFocusEffect(() => {
    setShouldHide(false);
    validateItem();
    if (forReview != true) {
      let _disposerOnPatch: IDisposer
      _disposerOnPatch = onPatch(itemsStore, patch => {
        validateItemDebounced();
      })
      return () => {
        setShouldHide(true)
        _disposerOnPatch();
      }
    }
    return undefined;
  });


  const itemStatus = itemsStore.itemActive.status;
  const upload = async () => {
    await itemsStore.upload({ itemIDX: itemsStore.itemActiveIDX });
    saveAndGo();
  }
  if (itemStatus == ITEM_STATUS.draft) {
    if (pageActive != (form.pages.length - 1))
      return <Button width={50} height={50} px="$2" variant="solid" isDisabled={true} key="actionButton-checkHidden" onPress={() => {}}>
      <ButtonIcon as={Ionicons} name="checkmark" color="$primary400"  size="xl"/>                 
    </Button>;
    if (forReview == false)
      return <Button width={50} height={50} px="$2" variant="solid" isDisabled={!isValidItem} key="actionButton-saveToPreview"  onPress={() => {saveAndGo({ goTo: "ItemDetails", forReview: true })}}>
      <ButtonIcon as={Ionicons} name="checkmark"  size="xl"/>                 
    </Button>;
    if (forReview == true)
      return <IconButton  width={50} height={50} variant="solid" key="actionButton-closeItem"
        icon={<Icon as={Ionicons} name="lock-closed" size="xl" />}
        onPress={() => saveAndGo({ goTo: ItemsListScreenName, itemStatus: ITEM_STATUS.closed, forReview: true })}
      />;
  }

  if (itemStatus == ITEM_STATUS.closed) {
    return <><IconButton variant="solid" isDisabled={itemsStore.itemActive.readOnly == true}
      icon={<Icon key="actionButton-openItem" as={Ionicons} name="lock-open" size="xl" />}
      onPress={() => saveAndGo({ goTo: "ItemDetails", itemStatus: ITEM_STATUS.draft, forReview: false })}
    /><IconButton variant="solid" key="actionButton-uploadItem"
      icon={<Icon as={MaterialCommunityIcons} name="cloud-upload" size="xl" />}
      onPress={upload}
      /></>;
  }

  if (itemStatus == ITEM_STATUS.sent) {
    return <><IconButton variant="solid"
      icon={<Icon as={Ionicons} name="mail" size="xl" />}
      onPress={() => openPDF(itemsStore, itemsStore.itemActive, true)}
    /><IconButton variant="solid"
      icon={<Icon as={FontAwesome5} name="file-pdf" size="xl"/>}
      onPress={() => openPDF(itemsStore, itemsStore.itemActive)}
      /></>;
  }
  return <></>;
};